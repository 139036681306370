import React from "react";
import pageImage from "images/HomepageLogo.png";
import { DocPageLayout, SEO, TypesBrowser, TypesDetails } from "components";

const DocumentationBrowser = ({ location }) => {
  const title = "ECL Documentation";
  const description = "Emerald Cloud Lab Type Documentation Browser";

  const searchParams = new URLSearchParams(location.search);
  const startExpanded = searchParams.get("toggles") === "open";
  const typeToDisplay = searchParams.get("type");

  return (
    <DocPageLayout headerText={"Documentation Center"}>
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      {typeToDisplay && (
        <TypesDetails typeName={typeToDisplay} startExpanded={startExpanded} />
      )}
      {!typeToDisplay && <TypesBrowser />}
    </DocPageLayout>
  );
};

export default DocumentationBrowser;
